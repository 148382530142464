<template>
  <div
    v-if="$apollo.loading || !page"
    class="d-flex flex-grow-1 py-5"
  >
    <b-spinner
      class="align-self-center mx-auto"
      :label="$t('Loading')"
      type="border"
      variant="primary"
    />
  </div>

  <div v-else-if="page">
    <div>
      <content-block
        v-for="contentBlock in page.contentBlocks"
        :key="contentBlock.id"
        :content-block="contentBlock"
      />
    </div>

    <template
      v-if="page.contentBlocks.length === 0"
    >
      <b-container>
        <b-row>
          <b-col>
            <h2>{{ page.title }}</h2>
            <p>(Empty page)</p>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </div>
</template>

<script>
import ContentBlock from '../components/ContentBlock.vue'
import gql from 'graphql-tag'
import { dom as fontAwesomeDom } from '@fortawesome/fontawesome-svg-core'

export default {
  components: {
    ContentBlock,
  },

  data() {
    return {
      fullPath: null,
      page: null,
    }
  },

  apollo: {
    page: {
      query: gql`
        query GetPage($fullPath: String!) {
          page(fullPath: $fullPath) {
            fullPath
            language
            metaDescription
            robotDirectives
            title

            contentBlocks {
              __typename
              ...on ContentBlockInterface {
                id
                position
              }
              ...on CardsRow {
                cards {
                  body
                  id
                  position
                  readMoreBody
                  readMoreCaption
                  title
                  titleImg
                }
                cardsPerRow {
                  xs
                  sm
                  md
                  lg
                  xl
                }
              }
              ...on CoverCarousel {
                carouselSlides {
                  buttonCaption
                  buttonHref
                  buttonIcon
                  heading
                  id
                  imageSrc
                  position
                }
              }
              ...on HtmlContainer {
                cssClasses
                fluid
                html
              }
              ...on Modal {
                body
                modalOptions {
                  footerClass
                  headerClass
                  okOnly
                  okTitle
                  size
                  title
                }
              }
            }

            translations {
              fullPath
              language
            }
          }
        }
      `,
      result(result) {
        this.onPageQueryResult(result)
      },
      skip() {
        return !this.fullPath
      },
      variables() {
        return { fullPath: this.fullPath }
      },
    },
  },

  computed: {
    metaDescriptionTag() {
      return document.querySelector('meta[name="description"]')
    },
    metaRobotsTag() {
      return document.querySelector('meta[name="robots"]')
    },
  },

  watch: {
    page(newVal) {
      if (newVal) this.onPageChanged()
    },
  },

  created() {
    this.fullPath = this.$route.params.pathMatch
  },

  methods: {
    createHeadTag(name, attributes) {
      const headTag = document.createElement(name)
      Object.entries(attributes).forEach(([key, value]) => headTag.setAttribute(key, value))
      document.head.appendChild(headTag)

      return headTag
    },
    createHeadTags(page) {
      this.createHeadTag('meta', { name: 'description', content: page.metaDescription })

      if (this.page.robotDirectives.length > 0) {
        this.createHeadTag('meta', { name: 'robots', content: page.robotDirectives.join(',') })
      }

      this.createHeadTag('link', { rel: 'canonical', href: `https://ganser.consulting${page.fullPath}` })

      page.translations.forEach(translation => {
        this.createHeadTag('link', {
          rel: 'alternate',
          href: `https://ganser.consulting${translation.fullPath}`,
          hreflang: translation.language,
        })
      })
    },
    onPageChanged() {
      document.title = this.page.title
      this.createHeadTags(this.page)

      this.trackPageView()

      fontAwesomeDom.watch()

      this.$emit('hydrated')
      this.$emit('page-changed', this.page)
    },
    onPageQueryResult(result) {
      if (result.data && result.data.page) {
        this.page = result.data.page
        return
      }

      const errorLocation = new URL(window.location)

      if (result.error) {
        errorLocation.pathname = this.$t('error-503-path')
      } else {
        errorLocation.pathname = this.$t('error-404-path')
      }

      errorLocation.searchParams.append('originalPath', this.$route.fullPath)

      window.location.replace(errorLocation.toString())
    },
    // See https://developer.matomo.org/guides/spa-tracking.
    trackPageView() {
      window._paq = window._paq || []

      if (this.$root.referrer) {
        window._paq.push(['setReferrerUrl', this.$root.referrer])
      }

      window._paq.push(['setCustomUrl', `${window.location.pathname}${window.location.search}`])
      window._paq.push(['setDocumentTitle', document.title])
      window._paq.push(['deleteCustomVariables', 'page'])

      window._paq.push(['enableLinkTracking'])
      window._paq.push(['trackPageView'])
    },
  },

  beforeRouteUpdate(to, _from, next) {
    this.$root.referrer = window.location.href
    this.fullPath = to.params.pathMatch
    next()
  },
}
</script>

<i18n locale="de">
{
  "error-404-path": "/de/serverfehler/seite-nicht-gefunden",
  "error-503-path": "/de/serverfehler/seite-temporär-nicht-verfügbar"
}
</i18n>

<i18n locale="en">
{
  "error-404-path": "/en/server-errors/page-not-found",
  "error-503-path": "/en/server-errors/page-temporarily-unavailable"
}
</i18n>
