import Page from '../views/Page.vue'
import ServerError404 from '../views/ServerError404.vue'
import ServerError503 from '../views/ServerError503.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/de/serverfehler/seite-nicht-gefunden',
    component: ServerError404,
  },
  {
    path: '/de/serverfehler/seite-temporär-nicht-verfügbar',
    component: ServerError503,
  },

  {
    path: '/en/server-errors/page-not-found',
    component: ServerError404,
  },
  {
    path: '/en/server-errors/page-temporarily-unavailable',
    component: ServerError503,
  },

  {
    path: '*',
    name: 'page',
    component: Page,
  },
]

// See https://router.vuejs.org/guide/advanced/scroll-behavior.html.
const scrollBehavior = function(to, _from, savedPosition) {
  // If the browser's native history navigation (i.e. its back or forward button) is used, restore the historic scroll
  // position.
  if (savedPosition) {
    return savedPosition

  // If an anchor (i.e. #some-DOM-ID) is present in the new route, scroll to that location.
  } else if (to.hash) {
    return { selector: to.hash }

  // Else, scroll to the top left.
  } else {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
})

router.beforeEach((_to, _from, next) => {
  document.querySelectorAll('head link[rel=alternate][hreflang]').forEach(tag => tag.remove())
  document.querySelectorAll('head link[rel=canonical]').forEach(tag => tag.remove())
  document.querySelectorAll('head meta[name=description]').forEach(tag => tag.remove())
  document.querySelectorAll('head meta[name=robots]').forEach(tag => tag.remove())

  return next()
})

export default router
